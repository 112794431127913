import axios from 'axios';
import { CRP_TENANT_CODE, CRR_CLIENT_SECRET, GRANT_TYPE_REFRESH } from '../../utils/constants';
import { API } from '../../utils/api';

/**
 * @param {Object} params
 * @param {string} params.refresh_token
 * @returns {Promise<Object>} Promise resolving to the token response
 */
function getTokenUsingRefresh({ refresh_token }) {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', GRANT_TYPE_REFRESH);
  urlencoded.append('refresh_token', refresh_token);

  return axios
    .post(API.USER.getToken, urlencoded, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${CRR_CLIENT_SECRET}`,
      },
    })
    .then((res) => {
      return res?.data;
   })
    .catch((error) => {
      console.error('Error fetching token:', error);
      throw error;
    });
}

export default getTokenUsingRefresh;
