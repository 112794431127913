import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AuthService from '../../services/AuthService';
import authUtil from '../../utils/AuthUtils';
import { useNavigate } from 'react-router-dom';

import lightTheme from '../../themes/lightTheme';
// child components
import LoginPage from './LoginPage';
import notify from '../../utils/notifier';
import LoginForm from './LoginForm';

import { redirectToLogin } from '../../utils/user';
import { getUserTokens, setUserTokens, isAuthenticated } from "../../utils/user";
import getToken from "../../services/user/getToken";
import { REDIRECT_URLS } from '../../utils/constants';
import Util from '../../utils/Util';

const useStyles = makeStyles(lightTheme => ({
  root: {
    flexGrow: 1,
    // backgroundImage: `url(${backGround})`,
    height: '100vh',
    width: '100vw',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ['@media only screen and (max-width:600px)']: {
      padding: '20'
    }
  },
  rootLogin: {
    flexGrow: 1,
    transition: lightTheme.transitions.create('width', {
      easing: lightTheme.transitions.easing.easeOut,
      duration: 100
    }),
    height: '100vh',
    ['@media only screen and (max-width:1380px)']: {
      width: '74vw'
    },
    ['@media only screen and (min-width:1380px)']: {
      width: '80%'
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ['@media only screen and (max-width:600px)']: {
      padding: '20'
    }
  },
  register: {
    height: '100vh',
    width: '100%',
    borderRadius: '0'
  },
  paper: {
    //height: '100%',
    //width: '100%',
    borderRadius: '0',
    backgroundColor: 'transparent',
    overflowWrap: 'break-word',
    display: 'inline-block'
  },
  quoteContainer: {
    padding: '128px'
  },
  logoContainer: {
    marginBottom: '32px'
  },
  logo: {
    width: '20%',
    height: 'auto'
  },
  titleLine: {
    fontSize: '42px',
    fontWeight: '300',
    lineHeight: '1',
    color: '#ffffff',
    fontFamily: 'Helvetica Neue',
    overflowWrap: 'break-word',
    display: 'inline-block'
  },
  titleDescription: {
    fontSize: '3.0em',
    fontWeight: 300,
    lineHeight: 1,
    color: 'white'
  },
  productNameColor: {
    color: '#1976d2 !important'
  },
  introDescription: {
    paddingTop: '16px',
    fontSize: '1.0em',
    maxWidth: '50em',
    color: 'white'
  },
  responsiveGridLogo: {
    ['@media only screen and (max-width:600px)']: {
      display: 'none'
    }
  },
  loginButton: {
    float: 'right',
    padding: '16px'
  },
  loginButtonText: {
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'Roboto, sans-serif',
    color: '#039be5'
  },
  ssoFailedDiv: {
    padding: '8px'
  }
}));

export default function Onboard(props) {
  const classes = useStyles();
  const [loginDrawer, setLoginDrawer] = React.useState(false);

  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');

  const handleSSO = () =>{
      redirectToLogin();
  }

  React.useEffect(() => {
      const fetchToken = async () => {
          try {
              const { accessToken } = getUserTokens();

              if (!accessToken) {
                  if (code) {
                      const result = await getToken({
                          code: code,
                          redirect_uri: REDIRECT_URLS[Util.getEnv()],
                      });
                      setUserTokens(result);
  
                      window.location.href = `${window.location.origin}/`;
                  }
              } else {
                  window.location.href = `${window.location.origin}/`;
              }
          } catch (error) {
              console.error("Error fetching token:", error);
          }
      };
  
      fetchToken();
  }, [code, getUserTokens, setUserTokens]);

  return (
    <MuiThemeProvider theme={lightTheme}>
      <div className={classes.root}>
        <div className={classes.loginButton}>
          <Button className={classes.loginButtonText} onClick={handleSSO}>
            {'Login'}
          </Button>
        </div>
        <Grid container className={classes.register}>
          <LoginPage props={props} />
        </Grid>
      </div>
    </MuiThemeProvider>
  );
}
