import React, { useState, useEffect } from "react";
import notify from './notifier';
import {  Navigate , useLocation} from "react-router-dom";
import { isAuthenticated } from "../utils/user";

export const ProtectedRoute = ({ element: Component, ...rest }) => {
  const [authStatus, setAuthStatus] = useState(null); // `null` means loading

  useEffect(() => {
    const checkAuth = async () => {
      const val = await isAuthenticated();
      setAuthStatus(val);
    };
    checkAuth();
  }, []);

  if (authStatus === null) {
    // Show a loading indicator while checking authentication
    return <div>Loading...</div>;
  }
  const location = useLocation();
  if (authStatus) {
    return <Component {...rest} />;
  } else {
    notify({
      type: 'warn',
      message: 'Session timed out, please login again.',
      autoClose: 7000,
      limit: 1
    });

    // Redirect to the login page with a state for the redirection source
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};