import notify from './notifier';

const APP_ENV = `${process.env.REACT_APP_ENV}`;


/**
 * Converts a Uint8Array to Base64URL encoded string
 * @param {Uint8Array|ArrayBuffer} buffer - Data to encode
 * @returns {string} Base64URL encoded string
 */
function base64URLEncode(buffer) {
  // Convert buffer to base64
  const base64 = btoa(String.fromCharCode.apply(null, 
    buffer instanceof ArrayBuffer ? new Uint8Array(buffer) : buffer
  ));
  
  // Replace characters to make it URL-safe
  return base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}



const Util = {
  toJSONObj: str => {
    try {
      return JSON.parse(str);
    } catch (error) {}
  },

  toString: obj => {
    try {
      return JSON.stringify(obj);
    } catch (error) {
      notify({
        type: 'error',
        message: 'Unable to convert to String'
      });
    }
  },

  filterByValue: (array, value) => {
    return array.filter(
      data => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  },

  desc: (_a, _b, orderBy) => {
    //Sort order: Special chars > numbers > case-insensitive alpha,
    const reg = /[0-9]+/g;
    let a = _a[orderBy].toString();
    let b = _b[orderBy].toString();
    a = a.toLowerCase();
    b = b.toLowerCase();
    let v0 = a.replace(reg, v => v.padStart(10, '0'));
    let v1 = b.replace(reg, v => v.padStart(10, '0'));
    return v0.localeCompare(v1);
  },

  stableSort: (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  },

  getSorting: (order, orderBy) => {
    return order === 'asc'
      ? (a, b) => Util.desc(a, b, orderBy)
      : (a, b) => -Util.desc(a, b, orderBy);
  },

  objectHasValue: (obj, value) => {
    let _found = false;
    Object.keys(obj).forEach(key => {
      if (!_found) {
        let valStr = obj[key];
        valStr = valStr.toString().toLowerCase();
        _found = valStr.indexOf(value.toLowerCase()) !== -1 ? true : false;
      }
    });
    return _found;
  },

  searchByValue: (array, key) => {
    let result = array.filter(row => {
      return Util.objectHasValue(row, key);
    });
    return result;
  },

  extractFileNameFromDisposition: disposition => {
    var filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  },

  copyOf: obj => {
    return Object.assign({}, obj);
  },

  buildQueryParams: (query, options = null) => {
    let queryParams = '';
    for (let key in query) {
      queryParams += `&${key}=${query[key]}`;
    }

    for (let key in options) {
      queryParams += `&${key}=${options[key]}`;
    }

    if (queryParams) {
      queryParams = '?' + queryParams.slice(1);
    }

    return queryParams;
  },

  isAlphaNumericWithAllowedUnderscoresHyphens: str => {
    if (/^(?![0-9]*$)[a-zA-Z0-9-_]+$/.test(str)) {
      return true;
    }
    return false;
  },

  isAlphaNumericWithAllowedUnderscoresHyphensIntegers: str => {
    if (/^[a-zA-Z0-9-_]+$/.test(str)) {
      return true;
    }
    return false;
  },

  isAlphaNumericWithAllowedHyphensIntegers: str => {
    if (/^[a-zA-Z0-9-]+$/.test(str)) {
      return true;
    }
    return false;
  },

  isAlphaNumeric: str => {
    if (/^[a-zA-Z0-9]*$/.test(str)) {
      return true;
    }
    return false;
  },

  getEnv: () => {
    return APP_ENV;
  },

  /**
 * Generates a cryptographically random code verifier for PKCE
 * @returns {string} Base64URL encoded code verifier
 */
  generateCodeVerifier: () => {
    try {
      // Generate 32 bytes of random data
    const randomBytes = new Uint8Array(32);
    crypto.getRandomValues(randomBytes);
    
    // Convert to base64url encoded string
    return base64URLEncode(randomBytes);
    } catch (error) {
      throw error;
    }
    
  },

  /**
   * Generates a code challenge from a code verifier
   * @param {string} codeVerifier - The original code verifier
   * @returns {string} Base64URL encoded code challenge
   */
  generateCodeChallenge: async (codeVerifier) => {
    // Hash the code verifier using SHA-256
    const digest =  crypto.subtle.digest('SHA-256', 
      new TextEncoder().encode(codeVerifier)
    );
    
    // Convert the promise-based digest to base64url
    return digest.then(base64URLEncode);
  }
};
export default Util;
