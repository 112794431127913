import jwtDecode from 'jwt-decode'; // The actual named function is jwtDecode
import axios from "axios";
import { DUMMY_CLIENT_SECRET, CLIENT_NAME_MAP } from './constants';
import { isAccessTokenValid, isRefreshTokenValid, removeUserTokens, removeUserPreferences } from './user';
const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;
const BASE_API_KEY = `${process.env.REACT_APP_API_KEY}`;

// Authentication utility function
const authUtil = {

  setSession: (response) => {
    try {
        if (!response || typeof response !== 'object') {
            throw new Error("Invalid response object.");
        }

        const data = response.data;
        if (!data || typeof data !== 'object') {
            throw new Error("Invalid response data.");
        }

        const { token, clientName } = data;
        if (!token || typeof token !== 'string') {
            throw new Error("Invalid or missing token in response data.");
        }

        if (!clientName || typeof clientName !== 'string') {
            throw new Error("Invalid or missing clientName in response data.");
        }

        const decoded = jwtDecode(token);
        if (!decoded || typeof decoded.exp !== 'number') {
            throw new Error("Invalid or missing token expiry time.");
        }

        // Save validated data to localStorage
        localStorage.setItem('_locationServiceToken', token);
        localStorage.setItem('_clientName', clientName);
        localStorage.setItem('_locationServiceTokenExpiry', decoded.exp);

    } catch (error) {
        console.error("Error setting session:", error.message);
        throw error;
    }
  },

  updateToken: (newToken) => {
    if (authUtil.isAuthenticated()) {
        const decoded = jwtDecode(newToken);
        localStorage.setItem('_locationServiceToken', newToken);
        localStorage.setItem('_locationServiceTokenExpiry', decoded.exp);
    }
  },

  updateToken: newToken => {
    const decoded = jwtDecode(newToken);
    localStorage.setItem('_token', newToken);
    localStorage.setItem('_expiry', decoded.exp);
  },

  getToken: () => {
    if (authUtil.isAuthenticated()) {
      return localStorage.getItem('_token');
    } else {
      return '';
    }
  },

  getLocationServiceToken: async () => {
    try {
      const token = localStorage.getItem('_locationServiceToken');
      if (token === null || token === undefined) {
          return '';
          } else {
              if(authUtil.isTokenExpired()) {// If integration token is expired
                  // check for access token and refresh token
                  if(isAccessTokenValid() || isRefreshTokenValid()) {
                      // If true, regenerate it
                      const locationServiceIntegrationtoken = await authUtil.generateToken();
                      authUtil.setSession(locationServiceIntegrationtoken);
                      return documentServiceIntegrationtoken.data.token;
                  }
                  else {
                      // If false, clear tokens and redirect to login
                      removeUserTokens();
                      removeUserPreferences();
                      window.location.href = `${window.location.origin}/login`;
                  }
              }
              else{
                  return token;
              }
      }
    } catch (error) {
        throw error;
    }    
  },

  generateToken: async(client = null) => {
    if(!client) {
        client = localStorage.getItem('_clientName');
    }
    else {
        if(CLIENT_NAME_MAP[client]) {
            client = CLIENT_NAME_MAP[client];
          }
    }
    const requestObj = JSON.stringify({
        clientName: client,
        clientSecret: DUMMY_CLIENT_SECRET
    });
    const response =  axios.post(BASE_API_URL + '/v2/token', requestObj, {
        headers: {
            "Content-Type": "application/json",
            "x-api-key": BASE_API_KEY,
        }
    });

    return response;
  },

  isAuthenticated: () => {
    const token = localStorage.getItem('_locationServiceToken');
    if (token === null || token === undefined) {
        return false;
    } else {
        if(authUtil.isTokenExpired()) {// If integration token is expired
            // check for access token and refresh token
            if(isAccessTokenValid() || isRefreshTokenValid()) {
                // If true, regenerate it
            }
            else {
                // If false, clear tokens and redirect to login
                removeUserTokens();
                removeUserPreferences();
                window.location.href = `${window.location.origin}/login`;
            }
        }
        else{
            return true;
        }
    }
},

  isTokenExpired: () => {
    try {
      const expiry = parseInt(localStorage.getItem('_locationServiceTokenExpiry'), 10);
      return expiry < Math.floor(Date.now() / 1000);
    } catch (err) {
      return false;
    }
  },

  //clear tokens and localstorage
  clearSession: () => {
    localStorage.clear();
  }
};

export default authUtil;
