import React from 'react';
import { 
  IconButton, 
  Fab, 
  Card, 
  MenuItem, 
  ClickAwayListener,
  Typography,
  makeStyles,
  Divider 
} from '@material-ui/core';
import { 
  AccountCircle,
  Business,
  ExitToApp,
  SwapHoriz 
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 60,
    right: 0,
    zIndex: 1,
  },
  card: {
    minWidth: 280,
    padding: theme.spacing(2),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(0.5, 0),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  fab: {
    fontSize: '15px',
    color: "#173a64",
    background: "white",
    fontWeight: "500",
    height: "45px",
    width: "45px",
  },
  divider: {
    margin: theme.spacing(1.5, 0),
  },
  profileSection: {
    padding: theme.spacing(1, 0),
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  companyName: {
    fontSize: '0.9rem',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  companyRole: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  userInfo: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
  userName: {
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  }
}));

const UserProfileDropdown = ({ 
  userInitials, 
  loggedInClientName, 
  company, 
  userRole = "Admin", // Added role prop with default value
  onLogout,
  onChangeCompany // New prop for handling company change
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.wrapper}>
        <IconButton
          aria-label="user-settings"
          color="inherit"
          size="medium"
          onClick={handleClick}
        >
          <Fab size="medium" className={classes.fab}>
            <h3>{userInitials}</h3>
          </Fab>
        </IconButton>
        
        {open && (
          <div className={classes.paper}>
            <Card className={classes.card}>
              {/* User Profile Section */}
              <div className={classes.userInfo}>
                <Typography variant="h6" className={classes.userName}>
                  {loggedInClientName}
                </Typography>
              </div>

              {/* Company Section */}
              <div className={classes.profileSection}>
                <Typography className={classes.sectionTitle}>
                  My Profile
                </Typography>
                <MenuItem className={classes.menuItem}>
                  <Business />
                  <div>
                    <div className={classes.companyName}>{company}</div>
                    <div className={classes.companyRole}>{userRole}</div>
                  </div>
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={onChangeCompany}>
                  <SwapHoriz />
                  <Typography>Change company</Typography>
                </MenuItem>
              </div>

              <Divider className={classes.divider} />

              {/* Logout Section */}
              <MenuItem className={classes.menuItem} onClick={onLogout}>
                <ExitToApp />
                <Typography>Logout</Typography>
              </MenuItem>
            </Card>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default UserProfileDropdown;