
import { CRR_URLS, CRR_LOGIN_USER_BASE_PATH, CRR_CLIENT_MANAGEMENT_URLS, CRR_USER_MANAGEMENT_URLS } from "./constants"
import Util  from "./Util";
const env = Util.getEnv();

export const API = {
    USER: {
        loginRedirect: CRR_URLS[env] + `/auth/realms/dtworld/protocol/openid-connect/auth?code_challenge_method=S256&client_id=fs&response_type=code`,
        getToken: CRR_URLS[env] + `/auth/realms/dtworld/protocol/openid-connect/token`,
        getTokenUsingRefresh: CRR_URLS[env] + `/auth/realms/dtworld/protocol/openid-connect/token`,
        getPrivileges: CRR_CLIENT_MANAGEMENT_URLS[env] + '/users/current/company/{companyReference}/client-type/service-provider/privileges',
        getWorkspaces: CRR_CLIENT_MANAGEMENT_URLS[env] + '/users/current/workspaces',
        getUserInfo: CRR_USER_MANAGEMENT_URLS[env] + '/users/current',
        crrUserAbout: CRR_LOGIN_USER_BASE_PATH[env] + `/redirect/redirection/FS?redirect_url=${CRR_LOGIN_USER_BASE_PATH[env]}/user-profile/about`
        
    }
}