import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Paper, Grid, Typography } from '@material-ui/core';
import locationIcon from '../../../icons/LocationPinBlue.svg';

const AddressDetails = () => {
  const location = useLocation(); // Get location object
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const addressData = JSON.parse(decodeURIComponent(queryParams.get("data")));

  const handleBackClick = () => {
    navigate(-1);
  };
  const handleDeleteClick = () => {
    console.log('Deleting Address...');
  };

  if (!addressData) {
    return <div>No data available</div>; // Handle the case where no data is passed
  }

  const statusStyle = {
    color: addressData.isActive ? 'green' : 'red'
  };

  const fieldStyle = {
    marginBottom: '10px' // Space between individual fields
  };
  return (
    <div style={{backgroundColor: '#F2F2F2', height: 'calc(100vh - 144px)', margin: '50px'}}>
      {/* Header with Back button and code */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '80px'}}>
        <Button style={{color: '#298BC1',fontSize: '28px', paddingInline: '0px', marginBottom: '2px'}} onClick={handleBackClick}>
          &lt;
        </Button>
        <Typography variant="h7" style={{ marginLeft: '2px', color: '#298BC1'}}>
          <b>BACK</b>
        </Typography>
        <Typography variant="h7" style={{ marginLeft: '20px' }}>
          Code: <b>{addressData.id}</b>
        </Typography>
      </div>

      {/* Main Paper containing address details */}
      <Paper style={{ padding: '20px', backgroundColor: '#FFFFFF' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={locationIcon} 
            alt="Locations" 
            style={{ width: '60px', height: '60px', marginBottom: '8px', color: 'red'}} // Adjust width and height as needed
          />
          <div style={{ marginBottom: '15px', fontSize: '18px' }}>
            <b> { addressData.line1 } </b>
          </div>
        </div>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Status
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{...statusStyle, fontWeight: 'bold'}}>
                  {addressData.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
            </Typography>
          </div>
        </Grid>
        
        
        
        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              First Name
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.firstName || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Last Name
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.lastName || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Zip Code
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.zipCode || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Version
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.version || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Latitude
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.latitude || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Longitude
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.longitude || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Created At
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{(new Date(addressData.createdAt).toLocaleString()) || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Updated At
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{(new Date(addressData.updatedAt).toLocaleString()) || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              City
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.city || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              State
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.state || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={2}>
          <div style={fieldStyle}>
            <Typography variant="body1">
              Country
              <br />
              <div style={{ marginTop: '5px' }}>
                <span style={{ fontWeight: 'bold', color: 'black' }}>
                  <b>{addressData.country || 'N/A'}</b>
                </span>
              </div>
            </Typography>
          </div>
        </Grid>


        </Grid>
      </Paper>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button color="secondary" style={{ color: 'red', textTransform: 'none' }} onClick={handleDeleteClick}>
          <b>Delete Address</b>
        </Button>
      </div> */}
    </div>
  );
};

export default AddressDetails;
