import axios from 'axios';
import Util from '../utils/Util';
import authUtil from '../utils/AuthUtils';
import notify from '../utils/notifier';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;
const TOKEN_HEADER = 'x-location-service-integration-token';


// Helper function to create config
const getConfig = async () => {
  const token = await authUtil.getLocationServiceToken();
  return {
    headers: {
      "x-api-key":  process.env.REACT_APP_API_KEY,
      'x-location-service-integration-token' : token,
    },
  };
};

async function getAddressData(query){
  const config = await getConfig(); 
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/addresses/search` + queryParams, config);
}

async function downloadLocationsData(query) {
  const config = await getConfig(); 
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v3/locations` + queryParams, {
    responseType: 'blob',
    headers: {
      ...config.headers,
      'X-LS-FieldMask': '*',
    },
  });
}

async function downloadZonesData(query) {
  const config = await getConfig(); 
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/zones` + queryParams, {
    responseType: 'blob',
    headers: {
      ...config.headers,
    }
  });
}

async function downloadAddressesData(query) {
  const config = await getConfig(); 
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/addresses/search` + queryParams, {
    responseType: 'blob',
    headers: {
      ...config.headers,
    }
  });
}

async function getLocationsData(query) {
  const config = await getConfig(); 
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v3/locations` + queryParams, {
    headers: {
      ...config.headers,
      'X-LS-FieldMask': '*',
    },
  });
}

async function getZonesData(query) {
  const config = await getConfig(); 
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/zones` + queryParams, config);
}

async function getCountriesData() {
  const config = await getConfig(); 
  return axios.get(BASE_API_URL + `/v3/countries`, config);
}

async function getStatesData(query) {
  const config = await getConfig(); 
  if(!query._limit){
    query._limit = 100000;
  }
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/states` + queryParams, config);
}

async function getCitiesData(query) {
  const config = await getConfig(); 
  if(!query._limit){
    query._limit = 100000;
  }
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/v2/cities` + queryParams, config);
}

export default {
  getAddressData,
  getLocationsData,
  getZonesData,
  getCountriesData,
  getStatesData,
  getCitiesData,
  downloadLocationsData,
  downloadZonesData,
  downloadAddressesData
};
