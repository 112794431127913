import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Paper, Grid, Typography } from '@material-ui/core';
import locationIcon from '../../../icons/LocationPinBlue.svg';

const ZoneDetails = () => {
  const location = useLocation(); // Get location object
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const zoneData = JSON.parse(decodeURIComponent(queryParams.get("data")));

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDeleteClick = () => {
    console.log('Deleting zone...');
  };

  if (!zoneData) {
    return <div>No data available</div>; // Handle the case where no data is passed
  }

  const statusStyle = {
    color: zoneData.isActive ? 'green' : 'red'
  };

  const fieldStyle = {
    marginBottom: '10px' // Space between individual fields
  };
  return (
    <div style={{backgroundColor: '#F2F2F2', height: 'calc(100vh - 144px)', margin: '50px'}}>
      {/* Header with Back button and code */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '80px'}}>
        <Button style={{color: '#298BC1',fontSize: '28px', paddingInline: '0px', marginBottom: '2px'}} onClick={handleBackClick}>
          &lt;
        </Button>
        <Typography variant="h7" style={{ marginLeft: '2px', color: '#298BC1'}}>
          <b>BACK</b>
        </Typography>
        <Typography variant="h7" style={{ marginLeft: '20px' }}>
          Id: <b>{zoneData.id}</b>
        </Typography>
      </div>

      {/* Main Paper containing zone details */}
      <Paper style={{ padding: '20px', backgroundColor: '#FFFFFF' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src={locationIcon} 
            alt="Locations" 
            style={{ width: '60px', height: '60px', marginBottom: '8px', color: 'red'}} // Adjust width and height as needed
          />
          <div style={{ marginBottom: '15px', fontSize: '18px' }}>
            <b> {zoneData.name} </b>
          </div>
        </div>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item xs={6} sm={2}>
            <div style={fieldStyle}>
              <Typography variant="body1">
                Status
                <br />
                <div style={{ marginTop: '5px' }}>
                  <span style={{...statusStyle, fontWeight: 'bold'}}>
                    {zoneData.isActive ? 'Active' : 'In Active'}
                  </span>
                </div>
              </Typography>
            </div>
          </Grid>
          
          <Grid item xs={6} sm={2}>
            <div style={fieldStyle}>
              <Typography variant="body1">
                Type
                <br />
                <div style={{ marginTop: '5px' }}>
                  <span style={{ fontWeight: 'bold', color: 'black' }}>
                    <b>{zoneData.type || 'N/A'}</b>
                  </span>
                </div>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={6} sm={2}>
            <div style={fieldStyle}>
              <Typography variant="body1">
                Description
                <br />
                <div style={{ marginTop: '5px' }}>
                  <span style={{ fontWeight: 'bold', color: 'black' }}>
                    <b>{zoneData.description || 'N/A'}</b>
                  </span>
                </div>
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item xs={2} sm={2}>
            <div style={fieldStyle}>
              <Typography variant="body1">
                {zoneData.locodes && zoneData.locodes.length > 0
                  ? "Locodes"
                  : zoneData.zip_code_range
                  ? "Zip Code Range"
                  : zoneData.zip_codes && zoneData.zip_codes.length > 0
                  ? "Zip Codes"
                  : zoneData.location_codes && zoneData.location_codes.length > 0
                  ? "Location Codes"
                  : zoneData.node_codes && zoneData.node_codes.length > 0
                  ? "Node Codes"
                  : "No Data"}
                <br />
                {zoneData.locodes && zoneData.locodes.length > 0 ? (
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    {zoneData.locodes.map((item, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : zoneData.zip_code_range ? (
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li style={{ color: 'black' }}>
                      Start : {zoneData.zip_code_range.start}
                    </li>
                    <li style={{ color: 'black' }}>
                      End   : {zoneData.zip_code_range.end}
                    </li>
                  </ul>
                ): zoneData.zip_codes && zoneData.zip_codes.length > 0 ? (
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    {zoneData.zip_codes.map((item, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : zoneData.location_codes && zoneData.location_codes.length > 0 ? (
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    {zoneData.location_codes.map((item, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : zoneData.node_codes && zoneData.node_codes.length > 0 ? (
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    {zoneData.node_codes.map((item, index) => (
                      <li key={index} style={{ color: 'black' }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div style={{ marginTop: '10px', color: 'gray' }}>No items available</div>
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>

        
      </Paper>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button color="secondary" style={{ color: 'red', textTransform: 'none' }} onClick={handleDeleteClick}>
          <b>Delete Zone</b>
        </Button>
      </div> */}
    </div>
  );
};

export default ZoneDetails;
