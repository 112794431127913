import dayjs from 'dayjs';
import {
    ACCESS_TOKEN,
    ACCESS_TOKEN_EXPIRES_AT,
    ISSUER_CODE,
    REFRESH_TOKEN,
    REFRESH_TOKEN_EXPIRES_AT,
    SELECTED_BOARD,
    SELECTED_COMPANY,
    SELECTED_LOCATION,
    SELECTED_ROLE,
    USER_ID,
    ZONE_INFO,
    CRR_URLS,
    REDIRECT_URLS,
    CRP_TENANT_CODE,
    COMPANY_REFERENCE,
    COMPANY_NAME,
    USER_INITIALS,
    CLIENT_NAME,
    LOCATION_SERVICE_ACCESS_TOKEN,
    LOCATION_SERVICE_ACCESS_TOKEN_EXPIRY,
    CLIENT_NAME_MAP
  } from './constants';
import { API } from './api';
import Util from './Util';

import getTokenUsingRefresh from '../services/user/getTokenUsingRefresh';
const env = Util.getEnv();
export const addUserPreferences = (
    selectedCompany,
    selectedRole,
    selectedLocation,
    userId,
    issuerCode,
  ) => {
    localStorage.setItem(SELECTED_COMPANY, selectedCompany);
    localStorage.setItem(SELECTED_ROLE, selectedRole);
    localStorage.setItem(SELECTED_LOCATION, selectedLocation);
    localStorage.setItem(USER_ID, userId);
    localStorage.setItem(ISSUER_CODE, issuerCode);
};

export const setCompanyDetails = (companyRef, companyName) => {
    localStorage.setItem(COMPANY_REFERENCE, companyRef);
    localStorage.setItem(COMPANY_NAME, companyName);
}

export const setUserDetails = (data) => {
    localStorage.setItem(USER_ID, data.userId);
    localStorage.setItem(USER_INITIALS, data.userInitials);
}

export const setClientDetails = (clientName) => {
  if(CLIENT_NAME_MAP[clientName]) {
    clientName = CLIENT_NAME_MAP[clientName];
  }
  localStorage.setItem(CLIENT_NAME, clientName);
}


export const getUserPreferences = () => {
    return {
      [SELECTED_COMPANY]: localStorage.getItem(SELECTED_COMPANY),
      [SELECTED_ROLE]: localStorage.getItem(SELECTED_ROLE),
      [SELECTED_LOCATION]: localStorage.getItem(SELECTED_LOCATION),
      [USER_ID]: localStorage.getItem(USER_ID),
      [ISSUER_CODE]: localStorage.getItem(ISSUER_CODE),
      [ZONE_INFO]: localStorage.getItem(ZONE_INFO),
    };
};

export const removeUserPreferences = () => {
    localStorage.removeItem(SELECTED_COMPANY);
    localStorage.removeItem(SELECTED_ROLE);
    localStorage.removeItem(SELECTED_LOCATION);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(ISSUER_CODE);
    localStorage.removeItem(ZONE_INFO);
    localStorage.removeItem(SELECTED_BOARD);
  };

export const getUserTokens = () => {
    return {
        [ACCESS_TOKEN]: localStorage.getItem(ACCESS_TOKEN),
        [REFRESH_TOKEN]: localStorage.getItem(REFRESH_TOKEN),
        [REFRESH_TOKEN_EXPIRES_AT]: localStorage.getItem(REFRESH_TOKEN_EXPIRES_AT),
        [ACCESS_TOKEN_EXPIRES_AT]: localStorage.getItem(ACCESS_TOKEN_EXPIRES_AT),
    };
};

export const removeUserTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(LOCATION_SERVICE_ACCESS_TOKEN);
    localStorage.removeItem(LOCATION_SERVICE_ACCESS_TOKEN_EXPIRY);
    localStorage.removeItem(REFRESH_TOKEN_EXPIRES_AT);
    localStorage.removeItem(ACCESS_TOKEN_EXPIRES_AT);
};


export const setUserTokens = (data) => {
    const accessTokenExpiryTimeInUnix = String(
      dayjs().add(data.expires_in, 's').unix(),
    );
    const refreshTokenExpiresAtInUnix = String(
      dayjs().add(data.refresh_expires_in, 's').unix(),
    );
    return {
      [ACCESS_TOKEN]: localStorage.setItem(ACCESS_TOKEN, data.access_token),
      [REFRESH_TOKEN]: localStorage.setItem(REFRESH_TOKEN, data.refresh_token || ''),
      [REFRESH_TOKEN_EXPIRES_AT]: localStorage.setItem(
        REFRESH_TOKEN_EXPIRES_AT,
        refreshTokenExpiresAtInUnix,
      ),
      [ACCESS_TOKEN_EXPIRES_AT]: localStorage.setItem(
        ACCESS_TOKEN_EXPIRES_AT,
        accessTokenExpiryTimeInUnix,
      ),
    };
  };

  export const isRefreshTokenValid = () => {
    const { refreshTokenExpiresAt } = getUserTokens();
    if (refreshTokenExpiresAt) {
      return dayjs.unix(Number(refreshTokenExpiresAt)).isAfter(dayjs());
    }
    return false;
  };
  
  export const isAccessTokenValid = () => {
    const { accessTokenExpiresAt } = getUserTokens();
    if (accessTokenExpiresAt) {
      return dayjs.unix(Number(accessTokenExpiresAt)).isAfter(dayjs());
    }
    return false;
  };

  export const regenerateAccessToken = async () => {
    const { refreshToken } = getUserTokens();
    if (refreshToken) {
      return await getTokenUsingRefresh({ refresh_token: refreshToken })
        .then((userTokens) => {
          setUserTokens(userTokens);
          return userTokens.access_token;
        })
        .catch(() => {
          removeUserPreferences();
          removeUserTokens();
          window.location.href = `${window.location.origin}/login`;
        });
    } else {
      return false;
    }
  };

  export const isAuthenticated = async () => {
    if (isAccessTokenValid()) {
      return true;
    } 
    else if (isRefreshTokenValid()) {
      const accessToken = await regenerateAccessToken();
      return !!accessToken;
    } else {
      removeUserTokens();
      removeUserPreferences();
      window.location.href = `${window.location.origin}/login`;
    }
    return false;
  };

  export const redirectToLogin = async () => {
    const redirectUrl = REDIRECT_URLS[env];
    const codeVerifier = Util.generateCodeVerifier();
    
    const codeChallenge = await Util.generateCodeChallenge(codeVerifier);

    const loginRedirect = API.USER.loginRedirect + `&redirect_uri=${encodeURIComponent(redirectUrl)}&code_challenge=${encodeURIComponent(codeChallenge)}`;
    window.location.href = loginRedirect;
    localStorage.setItem('code_verifier', codeVerifier);

  };

  export const redirectToPostLogout = () => {
    const baseUrl = CRR_URLS[env];
    const redirectUrl = REDIRECT_URLS[env];
    const tenantCode = CRP_TENANT_CODE;
    // Construct the URL with dynamic parameters
    const finalUrl = `${baseUrl}?redirect_uri=${encodeURIComponent(redirectUrl)}&TENANT_CODE=${encodeURIComponent(CRP_TENANT_CODE)}`;

    window.location.href = finalUrl;
};

