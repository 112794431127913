import axios from 'axios';
import { API } from '../../utils/api';
import { ACCESS_TOKEN } from '../../utils/constants';
import authUtil from '../../utils/AuthUtils';
/**
 * Fetches all workspaces from the API that the user is a member of.
 * @returns {Promise<Object[]>} Promise resolving to an array of workspaces
 * @throws {Error} If there is an error fetching workspaces
 */
async function getWorkspaces() {
  let AUTH_TOKEN = localStorage.getItem(ACCESS_TOKEN);
  if(!AUTH_TOKEN) {
    authUtil.clearSession();
    window.location.href = `${window.location.origin}/login`;
  }
  return axios
    .get(API.USER.getWorkspaces, {
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
      },
    })
    .then((res) => {
        return res?.data;
    })
    .catch((error) => {
      console.error('Error fetching workspaces:', error);
      throw error;
    });
}

export default getWorkspaces;
