import axios from 'axios';
import { GRANT_TYPE, CRP_TENANT_CODE, CODE_VERIFIER, CRR_CLIENT_SECRET } from '../../utils/constants';
import { API } from '../../utils/api';

/**
 * @description
 * Fetches an access token from the CRR authentication server after user
 * authorization code has been received.
 *
 * @param {Object} params
 * @param {string} params.code - The authorization code
 * @param {string} params.redirect_uri - The redirect uri
 * @returns {Promise<Object>} Promise resolving to the token response
 */
const getToken = async function ({ code, redirect_uri }) {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', GRANT_TYPE);
  urlencoded.append('code', code);
  urlencoded.append('client_id', CRP_TENANT_CODE);
  urlencoded.append('redirect_uri', encodeURI(redirect_uri));
  urlencoded.append('code_verifier', localStorage.getItem(CODE_VERIFIER));

  return axios
    .post( API.USER.getToken, urlencoded, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${CRR_CLIENT_SECRET}`
      }
    })
    .then((res) => {
        return res?.data;
    })
    .catch((error) => {
      console.error('Error fetching token:', error);
      throw error;
    });
}

export default getToken;
