
export const SELECTED_COMPANY = 'selectedCompany';
export const SELECTED_ROLE = 'selectedRole';
export const SELECTED_LOCATION = 'selectedLocation';
export const USER_ID = 'userId';
export const USER_INITIALS = 'userInitials';
export const ISSUER_CODE = 'issuerCode';
export const GRANT_TYPE = 'authorization_code';
export const GRANT_TYPE_REFRESH = 'refresh_token';
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN_EXPIRES_AT = 'accessTokenExpiresAt';
export const REFRESH_TOKEN_EXPIRES_AT = 'refreshTokenExpiresAt';
export const ZONE_INFO = 'zoneInfo';
export const SELECTED_BOARD = 'selectedBoard';
export const CRP_TENANT_CODE = 'FS';
export const CRR_CLIENT_SECRET = 'RlM6WkUwd1d6WUdraWt1U29mYjZWek9WNTVtYnpSNDdVOGk=';
export const CODE_VERIFIER = 'code_verifier';
export const LOCATION_SERVICE_PROVIDER_CODE = 'LOCATION';
export const COMPANY_REFERENCE = 'company_ref';
export const COMPANY_NAME = 'company_name';
export const CLIENT_NAME = '_clientName';
export const LOCATION_SERVICE_ACCESS_TOKEN = '_locationServiceToken';
export const LOCATION_SERVICE_ACCESS_TOKEN_EXPIRY = '_locationServiceTokenExpiry';
export const ENVs = {
    prod: 'production',
    staging: 'staging',
    test: 'test',
    qa: 'qa',
    dev: 'development',
};
export const CLIENT_NAME_MAP = {
    'Foundation Services Super Admin Company': 'admin',
    'temp_datachain': 'datachain'
};

export const DUMMY_CLIENT_SECRET = "YbzH#O9&kiPyZ329r7v@gLAIxuUP+okD1*v8ZtxY";

export const CRR_URLS = {
    [ENVs.dev] : 'https://dev-login.cargoes.com',
    [ENVs.qa] : 'https://dev-login.cargoes.com',
    [ENVs.test] : 'https://dev-login.cargoes.com',
    [ENVs.staging] : 'https://dev-login.cargoes.com',
    [ENVs.prod] : 'https://dev-login.cargoes.com'
}

export const CRR_CLIENT_MANAGEMENT_URLS = {
    [ENVs.dev] : 'https://api-accountsdev.cargoes.com/clientmanagement-api',
    [ENVs.qa] : 'https://api-accountsdev.cargoes.com/clientmanagement-api',
    [ENVs.test] : 'https://api-accountsdev.cargoes.com/clientmanagement-api',
    [ENVs.staging] : 'https://api-accountsdev.cargoes.com/clientmanagement-api',
    [ENVs.prod] : 'https://api-accountsdev.cargoes.com/clientmanagement-api'
}

export const CRR_USER_MANAGEMENT_URLS = {
    [ENVs.dev] : 'https://api-accountsdev.cargoes.com/usermanagement-api',
    [ENVs.qa] : 'https://api-accountsdev.cargoes.com/usermanagement-api',
    [ENVs.test] : 'https://api-accountsdev.cargoes.com/usermanagement-api',
    [ENVs.staging] : 'https://api-accountsdev.cargoes.com/usermanagement-api',
    [ENVs.prod] : 'https://api-accountsdev.cargoes.com/usermanagement-api'
}

export const REDIRECT_URLS = {
    [ENVs.dev] : 'http://localhost:3000/login',
    [ENVs.qa] : 'https://test-locations-service.cargoes.com/login',
    [ENVs.test] : 'https://test-locations-service.cargoes.com/login',
    [ENVs.staging] : 'https://staging-locations-service.cargoes.com/login',
    [ENVs.prod] : 'https://locations-service.cargoes.com/login'
}

export const CRR_LOGIN_USER_BASE_PATH = {
    [ENVs.dev] : 'https://dev-accounts.cargoes.com',
    [ENVs.qa] : 'https://dev-accounts.cargoes.com',
    [ENVs.test] : 'https://dev-accounts.cargoes.com',
    [ENVs.staging] : 'https://dev-accounts.cargoes.com',
    [ENVs.prod] : 'https://dev-accounts.cargoes.com'
}