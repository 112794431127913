import React from 'react';
import '../css/Topbar.css';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import authUtil from '../utils/AuthUtils';
import logo from '../images/Cargochain.png';
import getWorkspaces from "../services/user/getWorkspaces";
import getUserInfo from "../services/user/getUserInfo";
import { setCompanyDetails, setUserDetails, setClientDetails } from "../utils/user";
import UserProfileDropdown from "./Onboard/UserProfileDropdown";
import ChangeCompanyModal from "./ChangeCompanyModal";
import notify from "../utils/notifier";
import CircularProgress from '@material-ui/core/CircularProgress';
import setApplicationNamespacesInLocal from '../services/ClientService';

const useStyles = makeStyles(theme => ({
  flexGrow0: {
      flexGrow: 0,
      marginRight:10,
  },
  wrapper: {
      position: "relative"
  },
  paper: {
      position: "absolute",
      top: 56,
      marginRight:40,
      right: -24,
      maxHeight: 400,
      padding: theme.spacing(1),
      padding: 2,
      overflow: "scroll",
  },
  card: {
      minWidth: 100,
      height: "100%"
  },
}));

const Topbar = () => {
  const classes = useStyles();
  const [loggedInClientName, setLoggedInClientName] = React.useState('');
  const [userInitials, setUserInitials] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [isChangeCompanyModalOpen, setIsChangeCompanyModalOpen] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [currentCompanyIndex, setCurrentCompanyIndex] = React.useState(0);
  const [companyRef, setCompanyRef] = React.useState('');
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [userDetails, setUserDetailsState] = React.useState({
      userInitials: '',
      fullName: '',
      role: 'FS Super Admin' // Default role, can be updated from actual user data
  });
  const navigate = useNavigate();



  const handleClickLogout = () => {
    authUtil.clearSession();
    navigate('/login');
  };


  const handleChangeCompany = () => {
    setIsChangeCompanyModalOpen(true);
  };

  const handleChangeCompanySubmit = async (newCompanyRef) => {
    try {
        const newIndex = companies.findIndex(company => company.companyOverview.companyReference === newCompanyRef);
        if (newIndex === -1) {
            throw new Error('Selected company not found');
        }
        
        if (newIndex !== -1) {
            const selectedCompany = companies[newIndex];
            const newCompanyName = selectedCompany.companyOverview.companyName;
            const newCompanyRef = selectedCompany.companyOverview.companyReference;

            // Update localStorage
            localStorage.setItem('company_name', newCompanyName);
            localStorage.setItem('company_ref', newCompanyRef);
            
            // Update state
            setCompanyDetails(newCompanyRef, newCompanyName);
            setCompany(newCompanyName);
            setCompanyRef(newCompanyRef);
            
            // Get and set new user info
            const userDetails = await getUserInfo(newCompanyRef);
            setUserDetails(userDetails);
            setUserDetailsState({
                userInitials: userDetails.userInitials,
                fullName: userDetails.fullName,
                role: userDetails.role || 'FS Super Admin' // Use role from API or default
            });
            
            // Set client details
            setClientDetails(newCompanyName);
            
            // Update documentServiceIntegration token
            const locationServiceIntegrationtoken = await authUtil.generateToken(newCompanyName);
            authUtil.setSession(locationServiceIntegrationtoken);

            await setApplicationNamespacesInLocal();

            setCurrentCompanyIndex(newIndex);

            // Close modal
            setIsChangeCompanyModalOpen(false);

            window.location.reload();

            // Show success message
            notify({
                type: "success",
                message: "Company changed successfully"
              });

        }
    } catch (error) {
        notify({
            type: "error",
            message: "Failed to change company"
          });
        console.error('Error changing company:', error);
    }
  };


  React.useEffect(() => {
    async function initializeApp(currentCompanyIndex) {
      try {
        const workspaceDetails = await getWorkspaces();
        const companyRef = workspaceDetails[currentCompanyIndex] ? workspaceDetails[currentCompanyIndex].companyOverview.companyReference : 'NA';
        const companyName = workspaceDetails[currentCompanyIndex] ? workspaceDetails[currentCompanyIndex].companyOverview.companyName : 'NA';
        setCompanyDetails(companyRef, companyName);
        setCompany(companyName);
        setCompanies(workspaceDetails);

        const userDetails = await getUserInfo(companyRef);
        setUserDetails(userDetails);
        setUserDetailsState({
        userInitials: userDetails.userInitials,
        fullName: userDetails.fullName,
        role: userDetails.role || 'FS Super Admin'
        });
        setUserInitials(userDetails.userInitials);
        setLoggedInClientName(userDetails.fullName);

        setClientDetails(companyName);
        
        const locationServiceIntegrationtoken = await authUtil.generateToken();
        authUtil.setSession(locationServiceIntegrationtoken);

        await setApplicationNamespacesInLocal();

        // Set initialized to true
        setIsInitialized(true);
      } catch (error) {
        console.error('Error in AdminApp initialization:', error);
        // Handle error (e.g., redirect to login, show error message)
      }
    }

    initializeApp(currentCompanyIndex);
  }, [currentCompanyIndex]);

  if (!isInitialized) {
  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
      </div>
  );
  }

  return (
    <div className="topbar">
      <div className="topbar-content">
        <img
          src={logo}
          alt="Logo"
          className="logo"
        />
      </div>
      <div className={classes.flexGrow0}>
      <UserProfileDropdown 
                                    userInitials={userDetails.userInitials}
                                    loggedInClientName={userDetails.fullName}
                                    company={company}
                                    userRole={userDetails.role}
                                    onLogout={handleClickLogout}
                                    onChangeCompany={handleChangeCompany}
                                />
      </div>
      <ChangeCompanyModal
                    open={isChangeCompanyModalOpen}
                    onClose={() => setIsChangeCompanyModalOpen(false)}
                    currentCompany={companyRef}
                    companies={companies.map(c => ({
                        companyReference: c.companyOverview.companyReference,
                        companyName: c.companyOverview.companyName
                    }))}
                    onSubmit={handleChangeCompanySubmit}
                />
    </div>
  );
};

export default Topbar;
