import { useState, useEffect } from 'react';
import LocationsService from '../../../services/LocationsService';
import { Grid, MenuItem, Select, FormControl, InputLabel, Typography } from '@material-ui/core';
import notify from '../../../utils/notifier';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Export columns, default filters, and manageColumns as constants
export const addressColumns = [
  { id: 'id', label: 'Id', width: '10%' },
  { id: 'line1', label: 'Line 1', width: '10%'  },
  { id: 'firstName', label: 'First Name', width: '10%'  },
  { id: 'lastName', label: 'Last Name', width: '10%'  },
  { id: 'zipCode', label: 'Zip Code', width: '10%'  },
  { id: 'city', label: 'City', width: '10%'  },
  { id: 'state', label: 'State', width: '10%'  },
  { id: 'country', label: 'Country', width: '10%'  },
  { id: 'version', label: 'Version', width: '10%'  },
  { id: 'isActive', label: 'Is Active', width: '10%'  },
  { id: 'actions', label: 'Actions', width: '10%'  },
];

export const addressManageColumns = {
  id: true,
  line1: true,
  zipCode: true,
  city: true,
  state: true,
  country: true,
  firstName: false,
  lastName: false,
  version: false,
  isActive: false
};

// Export the logic and state hooks for use in other components
export const Addresses = () => {
  const defaultApplicationNamespace = localStorage.getItem('_defaultApplicationNamespace');
  const defaultFilters = {
    city: '',
    state: '',
    country: '',
    state_code: '',
    country_code: '',
    application_namespace: defaultApplicationNamespace,
    is_active: true
  };
  const [addressesFilterValues, setAddressesFilterValues] = useState(defaultFilters);
  const [backdrop, setBackdrop] = useState(false);
  const applicationNamespaces = JSON.parse(localStorage.getItem('_applicationNamespaces'));
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);

  // Fetch countries data on mount
  useEffect(() => {
    if (!countries.length) {
      getCountriesData();
    }
  }, [countries]);

  // Fetch states and cities when the country filter changes
  useEffect(() => {
    if (addressesFilterValues.country) {
      getStatesData(addressesFilterValues.country);
      getCitiesData(addressesFilterValues.country);
    }
  }, [addressesFilterValues.country, countries]);

  // Fetch cities when the state filter changes
  useEffect(() => {
    if (addressesFilterValues.state) {
      getCitiesData(addressesFilterValues.country, addressesFilterValues.state);
    }
  }, [addressesFilterValues.state, states]);


  // Function to apply the filters
  const getAddressFilters = (filters) => {
    if (addressesFilterValues.city) {
      const city = cities.find(
        city =>
          city.name === addressesFilterValues.city &&
          city.countryIso2 === addressesFilterValues.country_code
      );
      filters['_cityId'] = city.id;
      delete filters._countryId;
      delete filters._stateId;
    } else if (addressesFilterValues.state) {
      const state = states.find(
        state =>
          state.name === addressesFilterValues.state &&
          state.countryIso2 === addressesFilterValues.country_code
      );
      filters['_stateId'] = state.id;
      delete filters._countryId;
    } else if (addressesFilterValues.country) {
      const country = countries.find(
        country =>
          country.name === addressesFilterValues.country &&
          country.iso2 === addressesFilterValues.country_code
      );
      filters['_countryId'] = country.id;
    } 
    if(addressesFilterValues.application_namespace){
      filters['applicationNamespace'] = addressesFilterValues.application_namespace;
    }
    filters['_isActive'] = addressesFilterValues.is_active;
    return filters;
  };

  const defaultFiltersForQuery = {
    application_namespace: addressesFilterValues.application_namespace || 'Default',
    is_active: addressesFilterValues.is_active || 'true'
  };

  const handleAddressesFilterClear = (forQuerySearch=false) => {
    if(forQuerySearch){
      setAddressesFilterValues(defaultFiltersForQuery);
    }else{
      setAddressesFilterValues(defaultFilters);
    }
  };

  // Function to fetch countries
  const getCountriesData = async () => {
    try {
      setBackdrop(true);
      const response = await LocationsService.getCountriesData();
      const countriesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
      const countryCodesData = countriesData.map(x => x.iso2).sort();
      setCountries(countriesData);
      setCountryCodes(countryCodesData);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching countries data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch states based on country
  const getStatesData = async countryName => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      setBackdrop(true);
      const response = await LocationsService.getStatesData(query);
      const statesData = response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
    
      setStates(statesData);
      setStateCodes(statesData.map(x => x.stateIso2));
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching states data' });
    } finally {
      setBackdrop(false);
    }
  };

  // Function to fetch cities based on country and state
  const getCitiesData = async (countryName, stateName = '') => {
    try {
      const country = countries.find(country => country.name === countryName);
      const query = { _countryIso2: country.iso2 };
      if (stateName) {
        const state = states.find(state => state.name === stateName);
        query['_stateIso2'] = state.stateIso2;
      }
      setBackdrop(true);
      const response = await LocationsService.getCitiesData(query);
      response.data.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });;
      setCities(response.data);
    } catch (error) {
      notify({ type: 'error', message: 'Error fetching cities data' });
    } finally {
      setBackdrop(false);
    }
  };

  const handleCountryFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.name === value);
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country_code: country.iso2
    }));
    getStatesData(value);
    getCitiesData(value);
  };

  const handleStateFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.name === value);
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state_code: state.stateIso2 || ''
    }));
    if (!addressesFilterValues.city) {
      getCitiesData(addressesFilterValues.country, value);
    }
  };

  const handleCityFilterChange = event => {
    const { name, value } = event.target;
    if (!addressesFilterValues.state) {
      const selectedCity = cities.find(city => city.name === value);
      setAddressesFilterValues(prevValues => ({
        ...prevValues,
        [name]: value,
        state: selectedCity ? selectedCity.stateName : prevValues.state,
        state_code: selectedCity ? selectedCity.stateIso2 : prevValues.state_code,
        country: selectedCity ? selectedCity.countryName : prevValues.country,
        country_code: selectedCity ? selectedCity.countryIso2 : prevValues.country_code
      }));
    } else {
      setAddressesFilterValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleCountryCodeFilterChange = event => {
    const { name, value } = event.target;
    const country = countries.find(country => country.iso2 === value);
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      country: country.name || ''
    }));
    getStatesData(country.name);
    getCitiesData(country.name);
  };

  const handleStateCodeFilterChange = event => {
    const { name, value } = event.target;
    const state = states.find(state => state.stateIso2 === value);
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
      state: state.name || ''
    }));
    getCitiesData(addressesFilterValues.country, state.name);
  };

  const handleApplicationNamespaceFilterChange = event => {
    const { name, value } = event.target;
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleActiveFilterChange = event => {
    const { name, value } = event.target;
    const parsedValue = value === 'true' ? 'true' : 'false';
    setAddressesFilterValues(prevValues => ({
      ...prevValues,
      [name]: parsedValue
    }));
  };

  const downloadAddressesData = async query => {
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }
      if(query._format === undefined || !query._format){
        query._format = 'CSV'
      }

      const response = await LocationsService.downloadAddressesData(query);

      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      
      const timestamp = new Date().toLocaleString('en-GB').replace(/[/,: ]/g, '-');
      const fileName = `addresses_${timestamp}.csv`;
      
      link.download = fileName; 
      link.click();
      setTimeout(() => {
        notify({
          type: 'success',
          message: 'File downloaded successfully.'
        });
      }, 50); 
    } catch (error) {
      console.error('Failed to download file:', error);
      notify({
        type: 'error',
        message: 'Something went wrong while downloading the file.'
      });
    }
  };

  // Function to fetch address data
  const fetchAddressesData = async query => {
    let output = {};
    try {
      if(query._isActive === undefined){
        query._isActive = true;
      }

      const response = await LocationsService.getAddressData(query);
      output.data = []
      response.data.forEach(response => {
        output.data.push({
          id : response.id,
          firstName: response.address.firstName,
          lastName: response.address.lastName,
          zipCode: response.address.zipCode,
          state: response.address.state,
          line1: response.address.line1,
          city: response.address.city,
          country: response.address.countryName,
          latitude: response.address.latitude,
          longitude: response.address.longitude,
          isActive: response.isActive,
          version: response.version,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt
        })
      })
      if (response.headers.hasOwnProperty('x-total-count')) {
        output['x-total-count'] = parseInt(response.headers['x-total-count']);
      }
    } catch (error) {
      notify({
        type: 'error',
        message: 'Internal Server Error'
      });
    }
    return output;
  };

  const addressesFilterConfig = [
    {
      name: 'city',
      label: 'City',
      isAutocomplete: true,
      type: 'select',
      options: cities,
      filterChange: handleCityFilterChange
    },
    {
      name: 'state',
      label: 'State',
      isAutocomplete: true,
      type: 'select',
      options: states,
      filterChange: handleStateFilterChange
    },
    {
      name: 'country',
      label: 'Country',
      isAutocomplete: true,
      type: 'select',
      options: countries,
      filterChange: handleCountryFilterChange
    },
    {
      name: 'state_code',
      label: 'State Code',
      isAutocomplete: true,
      type: 'select',
      options: stateCodes,
      filterChange: handleStateCodeFilterChange
    },
    {
      name: 'country_code',
      label: 'Country Code',
      isAutocomplete: true,
      type: 'select',
      options: countryCodes,
      filterChange: handleCountryCodeFilterChange
    },
    {
      name: 'is_active',
      label: 'Active',
      type: 'select',
      options: [
        { value: 'true', label: 'true' },
        { value: 'false', label: 'false' }
      ],
      filterChange: handleActiveFilterChange
    },
    {
      name: 'application_namespace',
      label: 'Application Namespace',
      type: 'select',
      options: applicationNamespaces,
      filterChange: handleApplicationNamespaceFilterChange
    }
  ];

  const RenderAddressesFiltersPreview = ({ mainFilters }) => {
    let filters = {}

    if(addressesFilterValues.application_namespace){
      filters['Application Namespace'] = addressesFilterValues.application_namespace
    }
    if(addressesFilterValues.is_active){
      filters['Active']  = addressesFilterValues.is_active
    }
    if(addressesFilterValues.country){
      filters.Country = addressesFilterValues.country
    }
    if(addressesFilterValues.state){
      filters.State = addressesFilterValues.state
    }
    if(addressesFilterValues.city){
      filters.City = addressesFilterValues.city
    }

    return (<div
      style={{
        marginBottom: '10px',
        padding: '5px',
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <Typography
        variant="subtitle2"
        style={{
          display: 'inline-block',
          fontWeight: 600,
          marginRight: '10px',
          marginLeft: '10px'
        }}
      >
        Filters :
      </Typography>
      <span style={{ display: 'flexible' }}>
        {Object.entries(filters).map(([key, value], index) => (
          <span
            key={key}
            style={{
              fontSize: '13px',
              padding: '3px 10px',
              backgroundColor: '#f9f9f9',
              marginRight: index !== Object.entries(filters).length - 1 ? '5px' : '0',
            }}
          >
            {`${key} : ${value}`}
          </span>
        ))}
      </span>
    </div>)
  };

  const renderAddressesFilters = () => {

    return addressesFilterConfig.map(filter => {
      const options = filter.options;
      const isCountrySelected =
        !!addressesFilterValues.country_code || !!addressesFilterValues.country;
      const isStateSelected =
        !!addressesFilterValues.state_code || !!addressesFilterValues.state;

      // Determine if the current filter should be disabled
      const isDisabled =
        ((filter.name === 'state' || filter.name === 'state_code' || filter.name === 'city') && !isCountrySelected) || (filter.name === 'city' &&
        !isStateSelected) || (filter.name === 'type' && !addressesFilterValues.application_namespace);
      return (
        <Grid item xs={4} key={filter.name}>
          <FormControl fullWidth>
          <InputLabel>{filter.isAutocomplete ? '' : filter.label}</InputLabel>
            {filter.isAutocomplete ? (
              <Autocomplete
                options={options.map(option => option.value || option.name || option)}
                value={addressesFilterValues[filter.name] || []}
                disabled={isDisabled}
                onChange={(event, newValue) => {
                  // Update the state for autocomplete
                  filter.filterChange({
                    target: {
                      name: filter.name,
                      value: newValue,
                    },
                  });
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label={filter.label} 
                    variant="standard" // Use standard variant for underline style
                  />
                )}
              
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {options.find(o => o.value === option)?.label || option}
                  </li>
                )}
              />
            ) : (
              <Select
              name={filter.name}
              value={addressesFilterValues[filter.name]}
              onChange={filter.filterChange}
              disabled={isDisabled}
              >
                {options.map(option => (
                  <MenuItem
                    key={option.value || option.name || option}
                    value={option.value || option.name || option}
                  >
                    {option.label || option.name || option}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>
      );
    });
  };

  return {
    addressesFilterValues,
    setAddressesFilterValues,
    handleAddressesFilterClear,
    downloadAddressesData,
    getAddressFilters,
    fetchAddressesData,
    backdrop,
    setBackdrop,
    addressesFilterConfig,
    renderAddressesFilters,
    RenderAddressesFiltersPreview
  };
};
