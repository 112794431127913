import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: 400,
    },
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
}));

const ChangeCompanyModal = ({
  open,
  onClose,
  currentCompany,
  companies = [],
  onSubmit,
}) => {
  const classes = useStyles();
  const [selectedCompany, setSelectedCompany] = React.useState(currentCompany);

  const handleChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(selectedCompany);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      aria-labelledby="change-company-dialog"
    >
      <DialogTitle id="change-company-dialog">Change Company</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel style={{backgroundColor: 'white'}}>Select Company</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={selectedCompany}
            onChange={handleChange}
            label="Select Company"
            fullWidth
          >
            {companies.length > 0 ? (
              companies.map((company) => (
                <MenuItem key={company.companyReference} value={company.companyReference}>
                  {company.companyName}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="">
                No companies available
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          variant="contained"
          disabled={!selectedCompany || selectedCompany === currentCompany}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeCompanyModal;